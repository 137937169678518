import React, { useEffect } from "react";
import Header from "../../header";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CourseHeader from "../../header/index";

import StudentSideBar from "../sidebar";
import Footer from "../../footer";
import { InstructionsgetById } from "../../../Redux/AuthorInstructions/AuthorInstructionSlice";
import { useDispatch, useSelector } from "react-redux";
import Bannerpage from "../../Banner";

export default function JournalMalpracticeStatement() {

  const { homeJournalDataById } = useSelector((state) => state.Home)
  const { instructions } = useSelector((state) => state.instuctionData)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(InstructionsgetById(homeJournalDataById?.data[0]?.uuid));
  }, [])

  return (
    <>
      <>
        <Header />
        <CourseHeader activeMenu={"CourseDetails"} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        The Complete Web Developer Course 2.0
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className="inner-banner"  
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="instructor-wrap border-bottom-0 m-0">
                  <div className="about-instructor align-items-center">
                    <p>ISSN: 2165-7548</p>
                  </div>
                  <span className="web-badge mb-3"> <i className="fa-brands fa-whatsapp"></i> + 0000000</span>
                </div>
                <h2>Journal Name</h2>
                <p>
                  Learn Web Development by building 25 websites and mobile apps
                  using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
                </p>
                <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
                >
                  <div className="cou-info">
                    <p style={{ textAlign: "end" }}>Open Access</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <Bannerpage />

        <div className="container p-5">
          <Row>
            <StudentSideBar />
            <Col>
              <h1>Publication ethics & malpractice statement</h1>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="card overview-sec">
                    <div className="card-body">
                      <div dangerouslySetInnerHTML={{ __html: instructions?.data?.ethical_malpractices?.content }}></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="card overview-sec">
                    <div className="card-body">
                      <h5>Second Title</h5>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae neque in augue lacinia
                        elementum quis vitae nulla. Praesent vel enim leo. Vivamus placerat rutrum nibh, eu dictum
                        tellus fermentum vitae. Sed dapibus ipsum id mollis fringilla. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Quisque ornare erat a posuere sagittis. Aliquam arcu nulla,
                        facilisis at lobortis vel, pellentesque eget mauris. Vivamus lacinia efficitur ex non varius.
                        Integer felis nunc, venenatis eu neque sed, iaculis lacinia magna. Proin eget nibh luctus eros
                        finibus venenatis. Donec convallis libero vitae massa ultrices, consequat porta massa aliquet.
                        Pellentesque pellentesque, orci vitae fermentum convallis, lectus sapien auctor neque, ac
                        blandit erat orci id velit. Maecenas finibus purus justo, eget maximus eros laoreet in. Nullam
                        nec lacus nec metus dictum fringilla. Ut et ullamcorper lacus, sit amet dictum odio. In vitae
                        orci feugiat, interdum purus nec, interdum lorem.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="card overview-sec">
                    <div className="card-body">
                      <h5>Article Processing Charges (APC):</h5>
                      <p>Journal of Medical Diagnostic Methods is a self-supporting
                        journal and does not receive funding from any institution/government.
                        Hence, the operation of the Journal is solely financed by the handling
                        fees received from authors and some academic/corporate sponsors.
                        The handling fees are required to meet the maintenance of the journal.
                        Being an Open Access Journal, it does not receive any payments for
                        subscription, as the articles are freely accessible online. Authors
                        of articles are required to pay a fair handling fee for processing
                        their articles. However, there are no submission charges. Authors
                        are required to make payment only after their manuscript has been
                        accepted for publication.</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </Col>
          </Row>
        </div>
        <Footer />
      </>
    </>
    // <div className="main-wrapper">
    //   <StudentHeader activeMenu={"List"} />
    //   {/* BreadCrumb */}
    //   <div className="breadcrumb-bar">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-md-12 col-12">
    //           <div className="breadcrumb-list">
    //             <nav aria-label="breadcrumb" className="page-breadcrumb">
    //               <ol className="breadcrumb">
    //                 <li className="breadcrumb-item">
    //                   <Link to="/">Home</Link>
    //                 </li>
    //                 <li className="breadcrumb-item">Pages</li>
    //                 <li className="breadcrumb-item">Students List</li>
    //               </ol>
    //             </nav>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* BreadCrumb */}

    //   {/* Page Wrapper */}
    //   <div className="page-content">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-lg-12">
    //           {/* Filter */}
    //           <div className="showing-list">
    //             <div className="row">
    //               <div className="col-lg-6">
    //                 <div className="d-flex align-items-center">
    //                   <div className="view-icons">
    //                     <Link to="/students-grid" className="list-view">
    //                       <Grid size={19} />
    //                     </Link>
    //                     <Link to="/students-list" className="list-view active">
    //                       <List size={19} />
    //                     </Link>
    //                   </div>
    //                   <div className="show-result">
    //                     <h4>Showing 1-9 of 50 results</h4>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           {/* Filter */}

    //           <div className="row">
    //             {/* Instructor List */}
    //             <div className="col-lg-12">
    //               <div className="student-grid-blk">
    //                 {/* Row alignment */}
    //                 <div className="row">
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student01}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">Charles Dickens</Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>Brazil</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item iconMar">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item iconMar">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item iconMar">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student02}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">Gabriel Palmer</Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>Italy</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 {/* Row alignment */}

    //                 {/* Row alignment */}
    //                 <div className="row">
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student03}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">James Lemire</Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>Louisiana</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student04}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">Olivia Murphy</Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>France</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 {/* Row alignment */}

    //                 {/* Row alignment */}
    //                 <div className="row">
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student05}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">Rebecca Swartz</Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>Iceland</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student06}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">Betty Richards</Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>Louisiana</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 {/* Row alignment */}

    //                 {/* Row alignment */}
    //                 <div className="row">
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student07}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">
    //                             Jeffrey Montgomery
    //                           </Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>Brazil</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student08}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">Brooke Hayes</Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>United States</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 {/* Row alignment */}

    //                 {/* Row alignment */}
    //                 <div className="row">
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student09}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">
    //                             Gertrude Shorter
    //                           </Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>Louisiana</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student10}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">David Garza</Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>Tunisia</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 {/* Row alignment */}

    //                 {/* Row alignment */}
    //                 <div className="row">
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student11}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">Vivian Winders</Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>Louisiana</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-md-6">
    //                     <div className="student-list flex-fill">
    //                       <div className="student-img">
    //                         <Link to="/students-profile">
    //                           <img
    //                             className="img-fluid"
    //                             alt=""
    //                             src={Student12}
    //                           />
    //                         </Link>
    //                       </div>
    //                       <div className="student-content">
    //                         <h5>
    //                           <Link to="/students-profile">Sean Leon</Link>
    //                         </h5>
    //                         <h6>Student</h6>
    //                         <div className="student-info">
    //                           <div className="loc-blk d-flex justify-content-center">
    //                             <img src={LocIcon} className="me-1" alt="" />
    //                             <p>Spain</p>
    //                           </div>
    //                           <ul className="list-unstyled inline-inline profile-info-social">
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-facebook"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-twitter"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-instagram"></i>
    //                               </Link>
    //                             </li>
    //                             <li className="list-inline-item">
    //                               <Link to="#;">
    //                                 <i className="fa-brands fa-linkedin"></i>
    //                               </Link>
    //                             </li>
    //                           </ul>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 {/* Row alignment */}
    //               </div>
    //             </div>
    //             {/* Instructor List */}
    //           </div>

    //           {/* pagination */}
    //           <div className="row">
    //             <div className="col-md-12">
    //               <ul className="pagination lms-page">
    //                 <li className="page-item prev">
    //                   <Link
    //                     className="page-link"
    //                     to="#;"

    //                   >
    //                     <i className="fas fa-angle-left"></i>
    //                   </Link>
    //                 </li>
    //                 <li className="page-item first-page active">
    //                   <Link className="page-link" to="#;">
    //                     1
    //                   </Link>
    //                 </li>
    //                 <li className="page-item">
    //                   <Link className="page-link" to="#;">
    //                     2
    //                   </Link>
    //                 </li>
    //                 <li className="page-item">
    //                   <Link className="page-link" to="#;">
    //                     3
    //                   </Link>
    //                 </li>
    //                 <li className="page-item">
    //                   <Link className="page-link" to="#;">
    //                     4
    //                   </Link>
    //                 </li>
    //                 <li className="page-item">
    //                   <Link className="page-link" to="#;">
    //                     5
    //                   </Link>
    //                 </li>
    //                 <li className="page-item next">
    //                   <Link className="page-link" to="#;">
    //                     <i className="fas fa-angle-right"></i>
    //                   </Link>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //           {/* pagination */}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* Page Wrapper */}
    //   <Footer />
    // </div>
  );
}
