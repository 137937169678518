// App.js
import React from 'react';
import { Provider } from 'react-redux';
// import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { store
    // , persistedStore 
} from './store'; // Assuming your store.js is set up as discussed
import Approuter from './approuter'; // Import the router component

const App = () => {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={<div>Loading...</div>} persistor={persistedStore}> */}
        <Approuter />
      {/* </PersistGate> */}
    </Provider>
  );
};

export default App;
