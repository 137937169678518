import React, { useEffect } from "react";
import CourseHeader from "../../header/index";
import DetailsContent from "../../pages/course/courseDetails/detailsContent";
import Footer from "../../footer";
import { Link, useLocation, useParams } from "react-router-dom";
import { HomeJournalDatagetById } from "../../../Redux/home/homeslice";
import { useDispatch, useSelector } from "react-redux";
import Bannerpage from "../../Banner";
const Journalpage = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = useParams();

  useEffect(() => {
    dispatch(HomeJournalDatagetById(window.atob(id) !== 'sss' ? window.atob(id) : homeJournalDataById?.data?.[0]?.uuid));
  }, [])

  const { homeJournalDataById } = useSelector((state) => state.Home)
  return (
    <>
      <div className="main-wrapper">
        <CourseHeader activeMenu={"CourseDetails"} />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        The Complete Web Developer Course 2.0
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className="inner-banner"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="instructor-wrap border-bottom-0 m-0">
                  <div className="about-instructor align-items-center">
                  </div>
                </div>
                <h2>{homeJournalDataById?.data?.[0]?.journal_name}</h2>
                <p>
                  Learn Web Development by building 25 websites and mobile apps
                  using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
                </p>
                <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0" 
                style={{justifyContent:"end"}}
                >
                  <div className="cou-info">
                    <h4  style={{textAlign:"end"}}>{homeJournalDataById?.data?.[0]?.is_open_access ? "Open Access" : ''}</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
              <p>ISSN: {homeJournalDataById?.data?.[0]?.issn}</p>
              <span className="web-badge mb-3"> <i className="fa-brands fa-whatsapp" ></i> {homeJournalDataById?.data?.[0]?.whatsapp_number}</span>
              </div>
            </div>
          </div>
        </div> */}

        <Bannerpage />

        <DetailsContent pageData={homeJournalDataById?.data?.[0]} relavantTopics={location?.state?.journals} />

        <Footer />

      </div>
    </>
  );
};

export default Journalpage;