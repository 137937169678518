import React, { useEffect } from 'react'
import Footer from '../footer'
import Header from '../header'
import { useParams } from 'react-router-dom'
import { JournalList } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";

const Authorinstruction = () => {
    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList } = useSelector((state) => state.jounalListData)

    useEffect(() => {
        dispatch(JournalList(window.atob(name)));
    }, [name])

    // const RemoveTags = (string) => {
    //   const withoutTags = string.replace(/<\/?[^>]+(>|$)/g, "");
    //   return withoutTags;
    // }
    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">{journalList?.data?.page_name}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace'>
                <div className='container'>
                    <div className='card'>
                        <div className='card-body'>
                            {/* <p>Our journals are all open access. Each article published by Longdom Publishing S.L. follows a
                                specific format.</p> */}
                            <h4>Article Categories</h4>
                            <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div>
                            {/* <ul>
                                <li>Original Articles: reports of data from original research.</li>
                                <li>Reviews: comprehensive, authoritative descriptions of any subject within the scope of the
                                    journal. These articles are usually written by experts in the field who have been invited
                                    by the Editorial Board.</li>
                                <li>Case reports: reports of clinical cases that can be educational, describe a diagnostic or therapeutic dilemma, suggest an association or present an important adverse reaction. Authors should clearly describe the clinical relevance or implications of the case. All case report articles should indicate that informed consent to publish the information has been granted from the patients or their guardians.</li>
                                <li>Commentaries: short, focused, opinion articles on any subject within the scope of the journal. These articles are usually related to contemporary issues, such as recent research findings, and are often written by opinion leaders.                        </li>
                                <li>Methodology articles: present a new experimental method, test or procedure. The method described may be new, or may offer a better version of an existing method.</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Authorinstruction