import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../footer";
import CourseHeader from "../header/index";
import StudentSideBar from "../student/sidebar";

import { useDispatch, useSelector } from "react-redux";
import { SpissuesgetById } from "../../Redux/SpecialIssues/specialissueSlice";
import Bannerpage from "../Banner";

export default function Guidelines() {

  const { homeJournalDataById } = useSelector((state) => state.Home)
  const { spIssue } = useSelector((state) => state.spIssueData)


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SpissuesgetById(homeJournalDataById?.data[0]?.uuid));
  }, [])

  return (
    <>
      <CourseHeader activeMenu={"CourseDetails"} />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      All Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      The Complete Web Developer Course 2.0
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="inner-banner"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="instructor-wrap border-bottom-0 m-0">
                <div className="about-instructor align-items-center">
                  <p>ISSN: 2165-7548</p>
                </div>
                <span className="web-badge mb-3"> <i className="fa-brands fa-whatsapp"></i> + 0000000</span>
              </div>
              <h2>Journal Name</h2>
              <p>
                Learn Web Development by building 25 websites and mobile apps
                using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
              </p>
              <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
              >
                <div className="cou-info">
                  <p style={{ textAlign: "end" }}>Open Access</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Bannerpage />

      <div className="container pt-5">
        <Row>
          <StudentSideBar />
          <Col style={{ backgroundColor: "#fff" }}>
            <div>
              <h1>Special Issue Guidelines</h1>
              <div dangerouslySetInnerHTML={{ __html: spIssue?.data?.guidelines?.content }}></div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}
