import React, { useState } from "react";
import Header from "../header";
// import {Course1} from "../imagepath";
// import labimage from '../../assets/img/subject/labimage.jpg'
import Loginbg from "../../assets/img/journals-image.jpeg";
import TrendingCourse from "./slider/trendingCourse";
// import Companies from "./slider/companies";
// import BgBanner1 from "../../assets/img/bg-banner-01.png";
// import BgBanner2 from "../../assets/img/bg-banner-02.png";
// import UserLove from "../../assets/img/user-love.jpg";
// import Blog from "./slider/blog";
import Footer from "../footer";
// import Testimonial from "./slider/testimonial";
// import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// import CountUp from "react-countup";
// import { details } from "./slider/topics";
import about from '../../assets/img/Journal -About.jpeg'
import { HomeDataget, HomeJournalDataget } from "../../Redux/home/homeslice";
// const options = [
//   { label: "Category", value: "Category" },
//   { label: "Angular", value: "Angular" },
//   { label: "Node Js", value: "Node Js" },
//   { label: "React", value: "React" },
//   { label: "Python", value: "Python" },
// ];

import { useDispatch, useSelector } from "react-redux";

import 'bootstrap/dist/css/bootstrap.min.css';
import { PaginationControl } from 'react-bootstrap-pagination-control';


export const Home = () => {
  // const [setValue] = useState(null);
  // const [isActive, setIsActive] = useState(false);`
  // const [isActivetwo, setIsActivetwo] = useState(false);
  // const [isActivethree, setIsActivethree] = useState(false);
  // const [isActivefour, setIsActivefour] = useState(false);
  // const [isActivefive, setIsActivefive] = useState(false);
  // const [isActivesix, setIsActivesix] = useState(false);

  // const toggleClass = () => {
  //   setIsActive(!isActive);
  // };
  // const toggleClasstwo = () => {
  //   setIsActivetwo(!isActivetwo);
  // };
  // const toggleClassthree = () => {
  //   setIsActivethree(!isActivethree);
  // };
  // const toggleClassfour = () => {
  //   setIsActivefour(!isActivefour);
  // };
  // const toggleClassfive = () => {
  //   setIsActivefive(!isActivefive);
  // };
  // const toggleClasssix = () => {
  //   setIsActivesix(!isActivesix);
  // };

  // const formatValue = (value) => `${Math.floor(value)}`;

  // const style = {
  //   control: (baseStyles, state) => ({
  //     ...baseStyles,
  //     backgroundColor: "#FFDEDA",
  //     border: state.isFocused ? 0 : 0,
  //     paddingLeft: "5px",
  //     paddingTop: "5px",
  //     paddingBottom: "5px",
  //     // This line disable the blue border
  //     boxShadow: state.isFocused ? 0 : 0,
  //     borderRadius: state.isSelected ? "0" : "10px",
  //     fontSize: "14px",
  //     "&:hover": {
  //       border: state.isFocused ? 0 : 0,
  //       color: "black",
  //     },
  //     // eslint-disable-next-line no-dupe-keys
  //     borderRadius: "50px",
  //     outline: "none",
  //   }),
  //   menu: (base) => ({ ...base, marginTop: "2px" }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#FFDEDA" : "white",
  //     fontSize: "14px",
  //     "&:hover": {
  //       backgroundColor: "#FFDEDA",
  //     },
  //   }),
  //   indicatorSeparator: (base) => ({
  //     ...base,
  //     display: "none",
  //   }),
  //   dropdownIndicator: (base, state) => ({
  //     ...base,
  //     color: "black",
  //     transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
  //     transition: "250ms",
  //   }),
  // };

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const NavigateFun = (dataID, jounalsD) => {
    navigation(`/journal-page/${window.btoa(dataID)}`, {
      state: { journals: jounalsD } // 'state' is used to pass the data
    });
  }



  const [page, setPage] = useState(1);
  const limit = 10

  useEffect(() => {
    dispatch(HomeDataget());
    dispatch(HomeJournalDataget(page - 1, limit))
  }, [page])

  const { homeData, homeJournalData } = useSelector((state) => state.Home)

  return (
    <>
      <div className="main-wrapper">
        <Header />
        {/* banner */}
        {/* <section
          className="home-slide d-flex align-items-center"
          style={{ backgroundImage: "url(" + Loginbg + ")" }}
        >
          <div className="container">
            <div className="row ">
              <div className="col-md-7">
                <div className="home-slide-face aos" data-aos="fade-up">
                  <div className="home-slide-text ">
                    <h5>The Leader in Online Learning</h5>
                    <h1>Engaging &amp; Accessible Online Courses For All</h1>
                    <p>Own your future learning new skills online</p>
                  </div>
                  <div className="banner-content">
                    <form className="form" action="/course-list">
                      <div className="form-inner">
                        <div className="input-group homeSearch">
                          <i className="fa-solid fa-magnifying-glass search-icon" />
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Search School, Online eductional centers, etc"
                          />
                          <span className="drop-detail">
                            <Select
                              // className="select2-container"
                              options={options}
                              value={options.value}
                              defaultValue={options[0]}
                              placeholder="Category"
                              onChange={setValue}
                              styles={style}
                            ></Select>
                          </span>
                          <button className="btn sub-btn" type="submit">
                            <i className="fas fa-arrow-right" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="trust-user">
                    <p>
                      Trusted by over 15K Users <br />
                      worldwide since 2022
                    </p>
                    <div className="trust-rating d-flex align-items-center">
                      <div className="rate-head">
                        <h2>
                          <span className="d-flex">
                            <CountUp
                              start={0}
                              end={1000}
                              delay={1}
                              format={formatValue}
                            />
                            +
                          </span>
                        </h2>
                      </div>
                      <div className="rating d-flex align-items-center">
                        <h2 className="d-inline-block average-rating">4.4</h2>
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-center">
                <div className="girl-slide-img aos" data-aos="fade-up">
                  <img src={bannerimg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* /banner */}

        {/* Home banner bottom */}
        {/* <section className="section student-course">
          <div className="container">
            <div className="course-widget">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius align-items-center aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={PencilIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span>10</span>K
                            <span className="d-flex">
                              <CountUp
                                start={0}
                                end={10}
                                delay={1}
                                duration={4}
                              />
                              K
                            </span>
                          </h4>
                          <p>Online Courses</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={CourseIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span className="d-flex">
                              <CountUp start={0} end={200} delay={1} />+
                            </span>
                          </h4>
                          <p>Expert Tutors</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={CertificateIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span className="d-flex">
                              <CountUp
                                start={0}
                                end={6}
                                delay={1}
                                duration={5}
                              />
                              K+
                            </span>
                          </h4>
                          <p>Ceritified Courses</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={GratuateIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span className="d-flex">
                              <CountUp
                                start={0}
                                end={60}
                                delay={1}
                                duration={2}
                              />
                              K +
                            </span>
                          </h4>
                          <p>Online Students</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* Home banner bottom */}
        {/* Top Category with Owl Carousel */}
        <div style={{
          marginTop: "0%",
          padding: "5%",
          backgroundImage: "url(" + Loginbg + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "80vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <div className="container whitetext" >
            <div className="section-sub-head pt-2">
              <h2 style={{ color: "#fff", textAlign: "left", paddingTop: "20px" }}>Journal’s Feed</h2>
            </div>
            <div className="section-text aos" data-aos="fade-up">
              <p className="mb-0" style={{ color: "#fff", textAlign: "left", paddingTop: "20px" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                aenean accumsan bibendum gravida maecenas augue elementum et
                neque. Suspendisse imperdiet.
              </p>
            </div>
          </div>
        </div>


        <section className="section how-it-works">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12 ">
                <div className="section-header aos" data-aos="fade-up">
                  <div className="section-sub-head">
                    <span>About Us </span>
                    <h2>Welcome To Journal’s Feed</h2>
                  </div>
                </div>
                <div className="section-text aos" data-aos="fade-up">
                  <div dangerouslySetInnerHTML={{ __html: homeData?.data?.about_text }}></div>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean
                    accumsan bibendum gravida maecenas augue elementum et neque.
                    Suspendisse imperdiet.
                  </p> */}
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <img src={about} alt="About Image" style={{ height: "300px", width: "100%" }} />
              </div>
            </div>
          </div>
        </section>
        {/* Top Category with Owl Carousel */}

        {/* What's new Featured Course */}
        <section className="section new-course">

          <div className="container">
            <div className="section-header aos" data-aos="fade-up">
              <div className="section-sub-head">
                <span>What’s New</span>
                <h2>Scientific Journals</h2>
              </div>
              {/* <div className="all-btn all-category d-flex align-items-center">
                <Link to="/" className="btn btn-primary">
                  All Journals
                </Link>
              </div> */}
            </div>
            <div className="section-text aos" data-aos="fade-up">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                aenean accumsan bibendum gravida maecenas augue elementum et
                neque. Suspendisse imperdiet.
              </p>
            </div>
            <div className="course-feature">
              <div className="row">
                {homeJournalData?.data?.data.map(detail => (
                  <div key={detail.uuid} className="col-lg-3 col-md-6 d-flex">
                    <div className="course-box d-flex aos" data-aos="fade-up">
                      <div className="product">
                        <div className="product-img">
                          {/* <Link to="/journal-page"> */}
                          <img className="img-fluid" alt="" crossOrigin="anonymous" src={detail?.image_url} />
                          {/* </Link> */}
                        </div>
                        <div className="product-content">
                          <div className="course-group d-flex">
                            <div className="course-group-img d-flex">
                              {/* <Link to="/journal-page"> */}
                              {/* Add instructor image here if available */}
                              {/* </Link> */}
                              <div className="course-name">
                                <h4>
                                  {/* <Link to="/journal-page"> */}
                                  {detail.name}
                                  {/* </Link> */}
                                </h4>
                                <p>Author</p>
                              </div>
                            </div>
                          </div>
                          <div className="course-items">
                            <ul>

                              {detail.journals.map((item, index) => (
                                // <Link key={index} 
                                // // to={{
                                // //   pathname: `/journal-page/${window.btoa(item?.uuid)}`,
                                // //   state: { journal: item } // Passing data through the state
                                // // }}
                                // onClick={() => NavigateFun(item?.uuid, detail?.journals)}
                                // >
                                <li
                                  key={index}
                                  onClick={() => NavigateFun(item?.uuid, detail?.journals)}
                                >
                                  <Link> 
                                  {item?.journal_name} 
                                  </Link>
                                </li>
                                // </Link>
                              ))}
                            </ul>
                          </div>
                          <div >
                            <hr />
                            <div className="all-btn all-category d-flex align-items-center" >
                              {/* <Link to="/filter" className="btn btn-primary"> */}
                              <Link className="btn btn-primary">
                                VIEW MORE
                              </Link>
                              {/* </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <PaginationControl
                page={page}
                between={4}
                total={Number(homeJournalData?.data?.count)}
                limit={limit}
                changePage={(page) => {
                  setPage(page)
                }}
                ellipsis={1}
              />
            </div>
          </div>
        </section>
        <TrendingCourse />
        {/* Trending Course */}

        {/* Companies */}
        {/* <section className="section lead-companies">
          <div className="container">
            <div className="section-header aos" data-aos="fade-up">
              <div className="section-sub-head feature-head text-center">
                <span>Trusted By</span>
                <h2>500+ Leading Universities And Companies</h2>
              </div>
            </div>
            <div className="lead-group aos" data-aos="fade-up">
              <Companies />
            </div>
          </div>
        </section> */}
        {/* Companies */}

        {/* Share knowledge */}
        {/* <section
          className="section share-knowledge"
          style={{ backgroundImage: "url(" + BgBanner1 + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="knowledge-img aos" data-aos="fade-up">
                  <img src={Share} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="join-mentor aos" data-aos="fade-up">
                  <h2>Want to share your knowledge? Join us a Mentor</h2>
                  <p>
                    High-definition video is video of higher resolution and
                    quality than standard-definition. While there is no
                    standardized meaning for high-definition, generally any
                    video.
                  </p>
                  <ul className="course-list">
                    <li>
                      <i className="fa-solid fa-circle-check" />
                      Best Courses
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check" />
                      Top rated Instructors
                    </li>
                  </ul>
                  <div className="all-btn all-category d-flex align-items-center">
                    <Link to="/instructor-list" className="btn btn-primary">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* /Share knowledge */}

        {/* <section
          className="section user-love"
          style={{ backgroundImage: "url(" + UserLove + ")" }}
        >
          <div className="container">
            <div className="section-header white-header aos" data-aos="fade-up">
              <div className="section-sub-head feature-head text-center">
                <span>Check out these real reviews</span>
                <h2>Users-love-us Dont take it from us.</h2>
              </div>
            </div>
          </div>
        </section> */}
        {/* Testimonial Carousel */}
        {/* <Testimonial /> */}
        {/* Testimonial Carousel */}

        {/* Become a instructor */}
        {/* <section className="section become-instructors aos" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 d-flex">
                <div className="student-mentor cube-instuctor ">
                  <h4>Become An Instructor</h4>
                  <div className="row">
                    <div className="col-lg-7 col-md-12">
                      <div className="top-instructors">
                        <p>
                          Top instructors from around the world teach millions
                          of students on Mentoring.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="mentor-img">
                        <img className="img-fluid" alt="" src={Become2} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-flex">
                <div className="student-mentor yellow-mentor">
                  <h4>Transform Access To Education</h4>
                  <div className="row">
                    <div className="col-lg-8 col-md-12">
                      <div className="top-instructors">
                        <p>
                          Create an account to receive our newsletter, course
                          recommendations and promotions.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="mentor-img">
                        <img className="img-fluid" alt="" src={Become1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* /Become a instructor */}

        {/* Blog */}
        {/* <section
          className="section latest-blog"
          style={{ backgroundImage: "url(" + BgBanner2 + ")" }}
        >
          <div className="container">
            <div className="section-header aos" data-aos="fade-up">
              <div className="section-sub-head feature-head text-center mb-0">
                <h2>Latest Blogs</h2>
                <div className="section-text aos" data-aos="fade-up">
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Eget aenean accumsan bibendum gravida maecenas augue
                    elementum et neque. Suspendisse imperdiet.
                  </p>
                </div>
              </div>
            </div>
            <Blog />
            <div className="enroll-group aos" data-aos="fade-up">
              <div className="row ">
                <div className="col-lg-4 col-md-6">
                  <div className="total-course d-flex align-items-center">
                    <div className="blur-border">
                      <div className="enroll-img ">
                        <img src={Icon7} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="course-count">
                      <h3>
                        <span className="counterUp">253,085</span>
                      </h3>
                      <p>STUDENTS ENROLLED</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="total-course d-flex align-items-center">
                    <div className="blur-border">
                      <div className="enroll-img ">
                        <img src={Icon8} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="course-count">
                      <h3>
                        <span className="counterUp">1,205</span>
                      </h3>
                      <p>TOTAL COURSES</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="total-course d-flex align-items-center">
                    <div className="blur-border">
                      <div className="enroll-img ">
                        <img src={Icon9} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="course-count">
                      <h3>
                        <span className="counterUp">127</span>
                      </h3>
                      <p>COUNTRIES</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lab-course">
              <div className="section-header aos" data-aos="fade-up">
                <div className="section-sub-head feature-head text-center">
                  <h2>
                    Unlimited access to 360+ courses <br />
                    and 1,600+ hands-on labs
                  </h2>
                </div>
              </div>
              <div className="icon-group aos" data-aos="fade-up">
                <div className="offset-lg-1 col-lg-12">
                  <div className="row">
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon9} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon10} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon16} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon12} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon13} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon14} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon15} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon16} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon17} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img src={Icon18} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* /Blog */}

        {/* Footer */}
        <Footer />
        {/* /Footer */}
      </div>
    </>
  );
};

export default Home;
