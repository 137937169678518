import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// const [data, setData] = useState('')
const initialState = {
    isLogin: false,
    manuscript: {},
    toastData: {},
};

const homeSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setManuscriptById(state, action) {
            state.manuscript = action.payload;
        },
        settoastData(state, action) {
            state.toastData = action.payload;
        },
    },
});

export const { settoastData, setManuscriptById } = homeSlice.actions;

export const ManuscriptsgetById = (id) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/journal/submitManuScript/${id}`).then((res) => {
        // dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setManuscriptById(res.data));
        // localStorage.setItem("user", JSON.stringify(res.data))
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
    return response;
}
const manuscriptReducer = homeSlice.reducer;

export default manuscriptReducer;
