import React, { useEffect } from 'react'
import Footer from '../footer'
import Header from '../header'
import { useParams } from 'react-router-dom'
import { JournalList } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'

const Journalfilter = () => {
    const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList } = useSelector((state) => state.jounalListData)

    useEffect(() => {
        dispatch(JournalList(window.atob(name)));
    }, [name])

    // const RemoveTags = (string) => {
    //   const withoutTags = string?.replace(/<\/?[^>]+(>|$)/g, "");
    //   return withoutTags;
    // }

    return (
        <>
            <Header />
            {/* <PageHeader activeMenu="Support" /> */}

            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">Pages</li>
                                        <li className="breadcrumb-item">Support</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">{journalList?.data?.page_name}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace container'>
                <div className='card'>
                    <div className='card-body'>
                    <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div>
                        {/* <p>
                            Ranging from Agriculture, forestry and nutrition, Longdom Publications publish
                            well researched, peer-reviewed journals on diversified fields including Medicine,
                            Life Science, Biology, Engineering, Technology, and management. Longdom publishes
                            more than 100 Journals on Open Access platform and reaches over one million
                            readers across the globe. Longdom Journals encourages multidisciplinary research
                            in Pharmacology, Healthcare, Sociology, Economics and Business Management,
                            Material Sciences, and Life Sciences. By adhering to creative common licensing
                            policy, we vow for a free and equitable distribution of the researched information
                            to all without any added cost to the users to further academic and research
                            interests.
                        </p> */}
                    </div>
                </div>
                <div className="alphabet-container">
                    {alphabet.map(letter => (
                        <div key={letter} className="alphabet-letter">
                            {letter}
                        </div>
                    ))}
                </div>
                <div className='row mt-2'>
                    <div className='col-xl-4 col-md-6 col-sm-12 '>
                        <div className='card'>
                            <div className='card-body'>
                                <h5>Acute and Chronic Disease Reports</h5>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-4 col-md-6 col-sm-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <h5>Advancements in Genetic Engineering</h5>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-4 col-md-6 col-sm-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <h5>Advancements in Genetic Engineering</h5>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default Journalfilter