import React, { useEffect } from 'react'
import Footer from '../footer'
import Header from '../header'
import { useParams } from 'react-router-dom'
import { JournalList } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";

const PublicationEthics = () => {

    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList } = useSelector((state) => state.jounalListData)

    useEffect(() => {
        dispatch(JournalList(window.atob(name)));
    }, [name])

    // const RemoveTags = (string) => {
    //   const withoutTags = string?.replace(/<\/?[^>]+(>|$)/g, "");
    //   return withoutTags;
    // }

    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0"> Publication Ethics</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace'>
                <div className='container'>
                    <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div>
                    <h4>1. {journalList?.data?.page_heading}
                        {/* Ethical expectations */}
                         </h4>
                    <strong>
                        {journalList?.data?.page_heading_slug}
                        {/* Editors’ responsibilities */}
                        </strong>
                    <p>– To act in a balanced, objective and fair way while carrying out their expected duties,
                        without discrimination on grounds of gender, sexual orientation, religious or
                        political beliefs, ethnic or geographical origin of the authors. </p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PublicationEthics