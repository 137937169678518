import React, { useEffect } from "react";
import CourseHeader from "../header";
import '../Journal/journal.css'
import Footer from "../footer";
// import { MapPin } from "feather-icons-react/build/IconComponents";
import StudentSideBar from "../student/sidebar";
// import InnerBanner from "../../assets/img/Journalpagebackground.jpg";
import { Link } from "react-router-dom";
import Bannerpage from "../Banner";
import { useDispatch, useSelector } from "react-redux";
import { ContactInfoById } from "../../Redux/Contact/constactslice";

const JournalContact = () => {

  const { homeJournalDataById } = useSelector((state) => state.Home)
  const { contactinfo } = useSelector((state) => state.contactData)

  console.log(contactinfo, "sadsadsad")

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ContactInfoById(homeJournalDataById?.data[0]?.uuid));
  }, [])

  return (
    <>
      <CourseHeader activeMenu={"CourseDetails"} />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      All Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      The Complete Web Developer Course 2.0
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bannerpage />

      <div className="page-content">

        <div className="container">
          <h3>
            <center>
              Contact Us
            </center>
          </h3>
          <div className="row">
            <StudentSideBar />
            <div className="col-lg-9 col-md-8 mx-auto">
              <div className="support-wrap">
                <div className="row">
                  <div className="col-md-5 col-lg-5 col-xl-5 col-sm-6">
                    <strong>Address</strong>
                    <br />
                    <label dangerouslySetInnerHTML={{ __html: contactinfo?.data?.address }}>
                    </label>
                    <br />

                    <strong>Registered Address</strong>
                    <br />
                    <label
                      dangerouslySetInnerHTML={{ __html: contactinfo?.data?.registered_address }}
                    >
                    </label>
                    <br />

                    <strong>Phone Number</strong>
                    <br />
                    <label>
                      {contactinfo?.data?.phone_number}
                    </label>
                    <br />

                    <strong>Email</strong>
                    <br />
                    <label>
                      {contactinfo?.data?.email}
                    </label>
                    <br />

                    <strong>Reprints / Advertisement</strong>
                    <br />
                    <label>
                      {contactinfo?.data?.reprint_advertisement}
                    </label>

                  </div>
                  <div className="col-md-7 col-lg-7 col-xl-7 col-sm-6">
                    <h5> Journal Name : Lorem Ipsum</h5>
                    <form action="#">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your first Name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your first Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your email address"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Phone</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your phone"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Message</label>
                        <textarea
                          className="form-control"
                          placeholder="Write down here"
                          rows={4}
                          defaultValue={""}
                        />
                      </div>
                      <button className="btn-submit">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default JournalContact