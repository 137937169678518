import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// const [data, setData] = useState('')
const initialState = {
    isLogin: false,
    journalList: {},
    toastData: {},
};

const journalSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setJournalList(state, action) {
            state.journalList = action.payload;
        },
        settoastData(state, action) {
            state.toastData = action.payload;
        },
    },
});

export const { setJournalList, settoastData } = journalSlice.actions;

export const JournalList = (name) => async (dispatch) => {

    const response = await axios.get(`${process.env.REACT_APP_URL}/page?page_name=${name}`).then((res) => {
        // dispatch(settoastData({ status: true, message: "You are Loged in !" }));
        dispatch(setJournalList(res.data));
        // localStorage.setItem("user", JSON.stringify(res.data))
    })
        .catch((err) => {
            dispatch(settoastData({ status: false, message: err.response.data }));
        })
        return response;
}

const journalReducer = journalSlice.reducer;

export default journalReducer;
