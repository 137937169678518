import React, { useEffect } from 'react'
import Footer from '../footer'
import Header from '../header'
import { useParams } from 'react-router-dom'
import { JournalList } from "../../Redux/journal/journalslice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'

const SubjectFilter = () => {
    const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

    const { name } = useParams();
    const dispatch = useDispatch();
    const { journalList } = useSelector((state) => state.jounalListData)

    useEffect(() => {
        dispatch(JournalList(window.atob(name)));
    }, [name])


    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">Pages</li>
                                        <li className="breadcrumb-item">Support</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">Open Access Journals</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace container'>
                <div className='card'>
                    <div className='card-body'>
                        <div dangerouslySetInnerHTML={{ __html: journalList?.data?.page_content }}></div>
                    </div>
                </div>
                <div className="alphabet-container">
                    {alphabet.map(letter => (
                        <div key={letter} className="alphabet-letter">
                            {letter}
                        </div>
                    ))}
                </div>
                <div className='row pt-3'>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h6>Acute and Chronic Disease Reports</h6>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h6>Advancements in Genetic Engineering</h6>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h6>Advancements in Genetic Engineering</h6>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default SubjectFilter