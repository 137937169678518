import React, { useEffect } from "react";
import CourseHeader from "../../header/index";

import Footer from "../../footer";
import { Link, useParams } from "react-router-dom";
import user7 from '../../../assets/img/user/836.jpg'
import StudentSideBar from "../../student/sidebar";

import { useDispatch, useSelector } from "react-redux";
import { EditorialsgetById } from "../../../Redux/Editorial/editorialSlice";

const EditorinchiefIndividual = () => {


    const { homeJournalDataById } = useSelector((state) => state.Home)
    const { editorial } = useSelector((state) => state.editorialData)
    const { details, type } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(EditorialsgetById(homeJournalDataById?.data[0]?.uuid));
    }, [])

    return (
        <>
            <div className="main-wrapper">
                <CourseHeader activeMenu={"CourseDetails"} />

                <div className="breadcrumb-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="breadcrumb-list">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                Courses
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                All Courses
                                            </li>
                                            <li className="breadcrumb-item" aria-current="page">
                                                The Complete Web Developer Course 2.0
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="inner-banner"

                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="instructor-wrap border-bottom-0 m-0">
                                    <div className="about-instructor align-items-center">
                                        <p>ISSN: 2165-7548</p>
                                    </div>
                                    <span className="web-badge mb-3"> <i className="fa-brands fa-whatsapp"></i> + 0000000</span>
                                </div>
                                <h2>Journal Name</h2>
                                <p>
                                    Learn Web Development by building 25 websites and mobile apps
                                    using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
                                </p>
                                <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
                                >
                                    <div className="cou-info">
                                        <p style={{ textAlign: "end" }}>Open Access</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="page-content course-sec">
                    <div className="container">
                        <div className="row">
                            <StudentSideBar />
                            <div className="col-lg-9">
                                {type === "cheif" && editorial?.data?.[0]?.editor_in_chiefs?.filter(items => items.uuid === String(window.atob(details)))?.map((item, index) => {
                                    return <div className="card overview-sec" key={index}>
                                        <div className="card-body">
                                            <h5 className="subs-title">Editor-in-Chief</h5>
                                            {/* <h6>Course Description</h6> */}
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6 d-flex">
                                                    <div className="instructor-box flex-fill">
                                                        <div className="instructor-img">
                                                            <Link>
                                                                <img className="img-fluid" alt="" src={item?.image_url || user7} crossOrigin="anonymous" />
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-6 d-flex">
                                                    <div className="instructor-content">
                                                        <h5>
                                                            <Link style={{ textAlign: "center" }}>{item?.name}</Link>
                                                        </h5>
                                                        <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.about }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="instructor-content">
                                                <h5>
                                                    <Link style={{ textAlign: "center" }}>Biography</Link>
                                                </h5>
                                                <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.biography }}></div>
                                            </div>
                                            <div className="instructor-content">
                                                <h5>
                                                    <Link style={{ textAlign: "center" }}>Research Interest</Link>
                                                </h5>
                                                <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.research_interest }}></div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                                {(type === "board" || type === "boardb" || type === "boardr") && editorial?.data?.[0]?.editorial_boards?.filter(items => items.uuid === String(window.atob(details)))?.map((item, index) => {
                                    return <div className="card overview-sec" key={index}>
                                        <div className="card-body">
                                            <h5 className="subs-title">Editor-in-Chief</h5>
                                            {/* <h6>Course Description</h6> */}
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6 d-flex">
                                                    <div className="instructor-box flex-fill">
                                                        <div className="instructor-img">
                                                            <Link>
                                                                <img className="img-fluid" alt="" src={item?.image_url || user7} crossOrigin="anonymous" />
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-6 d-flex">
                                                    <div className="instructor-content">
                                                        <h5>
                                                            <Link style={{ textAlign: "center" }}>{item?.name}</Link>
                                                        </h5>
                                                        <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.about }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {type === "board" && <>
                                                <div className="instructor-content">
                                                    <h5>
                                                        <Link style={{ textAlign: "center" }}>Biography</Link>
                                                    </h5>
                                                    <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.biography }}></div>
                                                </div>
                                                <div className="instructor-content">
                                                    <h5>
                                                        <Link style={{ textAlign: "center" }}>Research Interest</Link>
                                                    </h5>
                                                    <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.research_interest }}></div>
                                                </div>
                                            </>}
                                            {type === "boardb" && <div className="instructor-content">
                                                <h5>
                                                    <Link style={{ textAlign: "center" }}>Biography</Link>
                                                </h5>
                                                <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.biography }}></div>
                                            </div>}
                                            {type === "boardr" && <div className="instructor-content">
                                                <h5>
                                                    <Link style={{ textAlign: "center" }}>Research Interest</Link>
                                                </h5>
                                                <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: item?.research_interest }}></div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                })}

                                {/* Course Content */}
                                {/* <div className="card content-sec">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <h5 className="subs-title">About the Journal</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end">
                        <p>Index Copernicus Value 2015: 62.2</p>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis erat augue,
                      aliquet vitae mattis in, lobortis vitae libero. Cras libero tellus,
                      semper eget tristique ut, dignissim nec nisl. Aenean facilisis massa
                      a felis mattis euismod. Nunc nec nisi at quam tristique varius.
                      Vivamus at magna est. Pellentesque vitae ipsum a enim placerat
                      fringilla vitae a ligula. Nullam vitae magna venenatis, aliquet
                      purus id, iaculis lorem. Mauris in vehicula risus, nec luctus
                      sapien. Nunc ullamcorper nibh non lorem efficitur facilisis.
                      Fusce lacinia velit est, vel iaculis sem porta in. Nam quis
                      lorem lobortis, vulputate sem malesuada, dignissim risus.
                    </p>
                    <p>
                      Mauris vel placerat urna. Nullam ac dapibus sapien. Nunc dapibus
                      luctus ex. Class aptent taciti sociosqu ad litora torquent
                      per conubia nostra, per inceptos himenaeos. Nunc lacus augue,
                      bibendum id odio sit amet, aliquet volutpat augue. Nunc ut libero
                      ut magna iaculis porta. Maecenas condimentum quam at neque eleifend
                      auctor. Nulla facilisi. Vestibulum ante ipsum primis in faucibus orci
                      luctus et ultrices posuere cubilia curae; Donec eu convallis risus.
                      Donec in tempor libero.
                    </p>

                  </div>
                </div> */}
                                {/* /Course Content */}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />

            </div>
        </>
    );
};

export default EditorinchiefIndividual;
