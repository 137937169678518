import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../Journal/journal.css'
import Footer from "../footer";
import StudentSideBar from "../student/sidebar";
import CourseHeader from "../header/index";
import Bannerpage from "../Banner";
import { useDispatch, useSelector } from "react-redux";
import { ArchivegetById } from "../../Redux/Archive/archiveSlice";

const Archive = () => {

    const { homeJournalDataById } = useSelector((state) => state.Home)
    const { archive } = useSelector((state) => state.archiveData)

    console.log(archive?.data, "archive")

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ArchivegetById(homeJournalDataById?.data[0]?.uuid));
    }, [])

    return (
        <>
            <CourseHeader activeMenu={"CourseDetails"} />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            All Courses
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            The Complete Web Developer Course 2.0
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div
                className="inner-banner"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="instructor-wrap border-bottom-0 m-0">
                                <div className="about-instructor align-items-center">
                                    <p>ISSN: 2165-7548</p>
                                </div>
                                <span className="web-badge mb-3"> <i className="fa-brands fa-whatsapp"></i> + 0000000</span>
                            </div>
                            <h2>Journal Name</h2>
                            <p>
                                Learn Web Development by building 25 websites and mobile apps
                                using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
                            </p>
                            <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
                            >
                                <div className="cou-info">
                                    <p style={{ textAlign: "end" }}>Open Access</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Bannerpage />

            <div className="container p-2">
                <Row>
                    <StudentSideBar />
                    <Col style={{ backgroundColor: "#fff" }}>
                        <div className="cardb">
                            <h1>Archive</h1>
                            {archive?.data?.map((item, index) => {
                                return <div key={index}>
                                    <div className="cardd">
                                        <div className="card-body">
                                            {item?.year}
                                        </div>
                                    </div>
                                    <ul className="">
                                        {item?.data?.map((subItem, subIndex) => {
                                            return <Link key={subIndex} to="/volume-page">
                                            {/*  */}
                                                <li><i className="fas fa-chevron-right" />&nbsp; Volume {subItem?.volume}, Issue {subItem?.issue}</li>
                                            </Link>
                                        })}

                                    </ul>
                                </div>
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
            <Footer />
        </>
    )
}

export default Archive